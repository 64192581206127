// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$EWB-primary: mat.define-palette(mat.$indigo-palette);
$EWB-accent: mat.define-palette(mat.$pink-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$EWB-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$EWB-theme: mat.define-light-theme((color: (primary: $EWB-primary,
        accent: $EWB-accent,
        warn: $EWB-warn,
      ),
    ));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($EWB-theme);

/* You can add global styles to this file, and also import other style files */

html,
body {
  height: 100%;
  font-family: 'Inter', system-ui, sans-serif;
  font-size: 14px;
  line-height: 1.15;
}

body {
  margin: 0;
  font-family: 'Inter', system-ui, sans-serif
}

.boxshaow {
  box-shadow: 3px 4px 5px 1px gray;
}

/* Hide the scrollbar for all elements */
body::-webkit-scrollbar {
  width: 0.5em;
  /* Set the width of the invisible scrollbar */
}

/* Optionally, you can style the scrollbar track */
body::-webkit-scrollbar-track {
  background-color: #f1f1f1;
  /* Change to your preferred color */
}

/* Optionally, you can style the scrollbar thumb (the draggable part) */
body::-webkit-scrollbar-thumb {
  background-color: #888;
  /* Change to your preferred color */
}

.mat-icon {
  color: #737373;
}

.iconColor {
  color: #38beff;
  font-size: 14px;
}

input:-internal-autofill-selected {
  appearance: menulist-button;
  background-image: none !important;
  background-color: transparent !important;
  color: fieldtext !important;
}

::ng-deep .mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:checked~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control:enabled:indeterminate~.mdc-checkbox__background,
.mat-mdc-checkbox .mdc-checkbox .mdc-checkbox__native-control[data-indeterminate=true]:enabled~.mdc-checkbox__background {
  border-color: #383a3e !important;
  background-color: #383a3e !important;
}

.form-control:focus {
  box-shadow: none !important;
}

.mat-mdc-snack-bar-container {
  &.success-snackbar {
    --mdc-snackbar-container-color: #10d999;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
    position: absolute !important;
    bottom: 5% !important;
  }
}

.mat-mdc-snack-bar-container {
  &.warning-snackbar {
    --mdc-snackbar-container-color: #f04141;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
    position: absolute !important;
    bottom: 5% !important;
  }
}

.mat-mdc-snack-bar-container {
  &.error-snackbar {
    --mdc-snackbar-container-color: #5e5959;
    --mat-mdc-snack-bar-button-color: #fff;
    --mdc-snackbar-supporting-text-color: #fff;
    position: absolute !important;
    bottom: 5% !important;
  }
}
.audio-progress-bar .mat-progress-bar-fill::after {
  background-color: green !important;
}
.dataTables_wrapper .dataTables_length{    padding: 10px;font-size:12px;}
.dataTables_wrapper .dataTables_filter{    padding: 10px;font-size:12px;}
.dataTables_wrapper .dataTables_info{    padding: 10px;font-size:12px;}
.dataTables_wrapper .dataTables_paginate{    padding: 10px;font-size:12px;}
.dataTables_wrapper .dataTables_paginate .paginate_button{padding:0.3em 0.7em;}
table.dataTable th{background-color: #989898;
    color: #fff;
    font-weight: 400!important;font-size:12px;}
    table.dataTable td{font-size:12px;}    
body{
  width: 52%;
    margin: 0 auto!important;
}    
@media (min-width: 1200px) {

  .container,
  .container-lg,
  .container-md,
  .container-sm,
  .container-xl {
    max-width: 100% !important;
  }
}
@media (max-width: 1024px) {
  body{
    width: 100%;
      margin: 0 auto!important;
  }
}
.container[_ngcontent-lcx-c107]>div[_ngcontent-lcx-c107] button[_ngcontent-lcx-c107] {
  margin-bottom: 0px !important;
}

.listFabBtn .container > div {
 align-items: end !important;
}
.listFabBtn .container{
  align-items: end!important;
}

@media (max-width: 991px) {
  .row{
    --bs-gutter-x: 0rem!important;
  }
  .container {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .fixed-bottom {
    width: 96%;
  }
}
.cursor-pointer {
  cursor: pointer;
}
